<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        ویرایش تبلیغات سراسری : {{ ad.id }}
      </h2>
      <p class="mt-3">از این قسمت می‌توانید سفارش خود را تکمیل یا ویرایش کنید</p>
    </template>
    <template #default>
      <div
        v-if="ad.views.length"
        class="
          p-3
          lg:p-5
          border
          rounded-md
          bg-blueGray-100
          border-blueGray-200
          flex flex-wrap
          mb-5
        "
      >
        <div class="w-full md:w-6/12 xl:w-5/12 p-3">
          <p class="mb-2">
            برای ثبت بنر ابتدا دکمه زیر را زده و سپس بنر را در تلگرام به pv ربات ارسال
            کنید
          </p>
          <div v-if="'ok' in res" class="my-5">
            <p v-if="res.ok">
              اکنون پیام مورد نظر خود را به ربات
              <a
                :href="'https://t.me/' + res.result.from.username"
                class="text-sky-500 font-bold"
                target="_blank"
              >
                {{ res.result.from.first_name }}
              </a>
              ارسال کنید
            </p>
            <p v-else>
              مشکلی پیش آمد ربات نتوانست به شما پیام دهد. احتمالا ربات را بلاک کرده‌اید یا
              هنوز آن را استارت نکرده‌اید. به pv ربات بروید و آن را استارت کنید
            </p>
          </div>
          <div v-if="'ok' in sendRes" class="my-5">
            <p v-if="sendRes.ok">
              بنر توسط ربات
              <a
                :href="'https://t.me/' + sendRes.result.from.username"
                class="text-sky-500 font-bold"
                target="_blank"
              >
                {{ sendRes.result.from.first_name }}
              </a>
              ارسال شد
            </p>
            <p v-else>
              مشکلی پیش آمد ربات نتوانست به شما پیام دهد. احتمالا ربات را بلاک کرده‌اید یا
              هنوز آن را استارت نکرده‌اید. به pv ربات بروید و آن را استارت کنید
            </p>
          </div>

          <p class="text-red-600" v-if="!ad.can_edit">فرصت ویرایش تمام شده</p>
          <Button class="mb-2" color="cyan" v-if="ad.can_edit" @click="sendPmToBot">
            ویرایش بنر توسط ربات
          </Button>
          <Button class="mb-2" color="teal" v-if="ad.message_id" @click="sendPreview">
            نمایش بنر در ربات
          </Button>
        </div>
        <div class="w-full md:w-6/12 xl:w-7/12">
          <div class="p-1 lg:px-10">
            نوع بنر :
            <span class="font-bold">
              {{ ad.file_type ? list_type[ad.file_type] ?? "سایر" : "نامشخص" }}
            </span>
          </div>
          <div class="p-1 lg:px-10">
            <hr class="border-coolGray-200 my-3" />
            متن :
            <div>{{ ad.text ?? "مشخص نشده" }}</div>
            <Button
              class="mt-2"
              color="amber"
              :loading="refreshing"
              @click="refresh"
              v-if="ad.can_edit"
            >
              بررسی مجدد
            </Button>
          </div>
        </div>
      </div>
      <ErrorBox :errors="errors" class="mb-5" />
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div
          v-if="keys?.length"
          class="p-3 lg:p-5 border rounded-md bg-blueGray-100 border-blueGray-200 mb-5"
        >
          <h2 class="px-2 text-lg font-bold">کلید های تکی</h2>
          <div
            class="grid grid-cols-1 2xl:grid-cols-2 gap-2 mt-3"
            v-for="(item, index) in keys"
            :key="index"
          >
            <MnrText
              v-model="item.text"
              placeholder="مثال: تبلیغات سراسری 🚀"
              hint="متن کلید را وارد کنید"
              :status="errors['keys_text_' + index] ? 'error' : ''"
              :msg="errors['keys_text_' + index]?.[0]"
            />
            <MnrText
              v-model="item.link"
              placeholder="مثال: https://t.me/mnrtab"
              hint="لینک کلید را وارد کنید"
              inputDir="ltr"
              :status="errors['keys_link_' + index] ? 'error' : ''"
              :msg="errors['keys_link_' + index]?.[0]"
            />
            <hr class="border-coolGray-200 my-3" v-if="index < keys.length - 1" />
          </div>
        </div>
        <div
          v-if="subKeys?.length"
          class="p-3 lg:p-5 border rounded-md bg-blueGray-100 border-blueGray-200 mb-5"
        >
          <h2 class="px-2 text-lg font-bold">کلید های زیر بنر</h2>
          <p class="text-red-600" v-if="!ad.can_edit">فرصت ویرایش تمام شده</p>
          <div
            class="grid grid-cols-1 2xl:grid-cols-2 gap-2 mt-3"
            v-for="(item, index) in subKeys"
            :key="index"
          >
            <MnrText
              v-model="item.text"
              placeholder="مثال: تبلیغات سراسری 🚀"
              hint="متن کلید را وارد کنید"
              :disabled="!ad.can_edit"
              :status="errors?.['subKeys_text_' + index] ? 'error' : ''"
              :msg="errors?.['subKeys_text_' + index]?.[0]"
            />
            <MnrText
              v-model="item.link"
              placeholder="مثال: https://t.me/mnrtab"
              hint="لینک کلید را وارد کنید"
              inputDir="ltr"
              :disabled="!ad.can_edit"
              :status="errors?.['subKeys_link_' + index] ? 'error' : ''"
              :msg="errors?.['subKeys_link_' + index]?.[0]"
            />
            <hr class="border-coolGray-200 my-3" v-if="index < subKeys.length - 1" />
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <Button
        class="text-sm h-10 lg:h-12"
        color="cyan"
        @click="submit"
        :disabled="disabaledForm"
      >
        ثبت
      </Button>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
// import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    BodySimple,
    Icon,
    MnrText,
    // MnrPassword,
    Button,
    ErrorBox,
  },
  data() {
    return {
      firstLoading: true,
      disabaledForm: true,
      refreshing: false,
      errors: [],
      ad: {},
      L_: _,
      keys: [],
      subKeys: [],
      res: {},
      sendRes: {},
      list_type: {
        photo: "عکس",
        video: "فیلم",
        text: "متن",
      },
    };
  },
  mounted() {
    let $this = this;
    $this.errors = {};

    $this.$axios
      .get("/api/ad-groups/" + $this.$route.params.adId, {
        params: {
          id: $this.$store.state.user.id,
        },
      })
      .then(function (response) {
        // console.log(response);
        // let keys = _.maxBy(
        //   Object.values(
        //     _.groupBy(_.orderBy(response.data.ad.keys, "date", "desc"), "date")
        //   ),
        //   "length"
        // );
        $this.ad = response.data.ad;
        $this.keys = response.data.keys || [];
        $this.ad.sub_keys.forEach(function (item) {
          $this.subKeys.push({
            id: item.id,
            text: item.text ?? "",
            link: item.link ?? "",
          });
        });
        // keys.forEach(function (item) {
        //   $this.keys.push({
        //     text: item.text ?? "",
        //     link: item.link ?? "",
        //   });
        // });
      })
      .catch(function (error) {
        $this.errors = error.data;
      })
      .finally(function () {
        $this.firstLoading = false;
        $this.disabaledForm = false;
      });
  },
  methods: {
    refresh() {
      let $this = this;
      $this.errors = {};

      $this.refreshing = true;

      $this.$axios
        .get("/api/ad-groups/" + $this.$route.params.adId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          $this.ad = response.data.ad;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.refreshing = false;
        });
    },
    sendPmToBot() {
      let $this = this;
      $this.sending = true;
      $this.res = {};
      $this.$axios
        .put("/api/ad-groups/" + $this.$route.params.adId + "/set-post", {
          id: $this.$store.state.user.id,
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data;
        })
        .catch(function (error) {})
        .then(function () {
          $this.sending = false;
        });
    },
    sendPreview() {
      let $this = this;
      $this.sending = true;
      $this.res = {};
      $this.$axios
        .post("/api/ad-groups/" + $this.$route.params.adId + "/send-preview", {
          id: $this.$store.state.user.id,
        })
        .then(function (response) {
          // console.log(response);
          $this.sendRes = response.data;
        })
        .catch(function (error) {})
        .then(function () {
          $this.sending = false;
        });
    },
    submit() {
      let $this = this;
      $this.errors = {};

      $this.keys.forEach(function (item, index) {
        if (!item.text) {
          $this.errors["keys_text_" + index] = ["لطفا متن کلید را وارد کنید"];
        }
        if (!item.link) {
          $this.errors["keys_link_" + index] = ["لطفا لینک کلید را وارد کنید"];
        } else if (!/(https?:\/\/[^\s]+)/g.test(item.link)) {
          $this.errors["keys_link_" + index] = ["فرمت لینک اشتباه است"];
        }
      });
      $this.subKeys.forEach(function (item, index) {
        if (!item.text) {
          $this.errors["subKeys_text_" + index] = ["لطفا متن کلید را وارد کنید"];
        }
        if (!item.link) {
          $this.errors["subKeys_link_" + index] = ["لطفا لینک کلید را وارد کنید"];
        } else if (!/(https?:\/\/[^\s]+)/g.test(item.link)) {
          $this.errors["subKeys_link_" + index] = ["فرمت لینک اشتباه است"];
        }
      });

      if (Object.keys($this.errors).length) return;
      $this.disabaledForm = true;

      $this.$axios
        .put("/api/ad-groups/" + $this.$route.params.adId, {
          id: $this.$store.state.user.id,
          keys: $this.keys,
          subKeys: $this.subKeys,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push({ name: "Ad", params: { id: $this.$store.state.user.id } });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
